<template>
  <div>
    <b-overlay :show="isLoading">
      <div v-if="order">
        <b-button
          variant="primary"
          class="mb-2"
          @click="$router.go(-1)"
        >
          <FeatherIcon icon="ArrowLeftIcon" />
          {{ $t('go_back') }}
        </b-button>
        <b-card>
          <b-row>
            <b-col
              cols="12"
              lg="4"
            >
              <OrderDetails
                :order="order"
              />
            </b-col>
            <b-col
              cols="12"
              lg="8"
            >
              <ProductDetails
                :order="order"
              />
            </b-col>
            <b-col
              cols="12"
              lg="4"
            >
              <DeliveryDestination
                :order="order"
              />
            </b-col>
            <b-col
              cols="12"
              lg="4"
            >
              <DeliveryOrigin
                :order="order"
              />
            </b-col>
            <b-col
              cols="12"
              lg="4"
            >
              <SmartTruck
                :order="order"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <ProofDelivery
                :order="order"
              />
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div v-else-if="isLoading">
        <b-row>
          <b-col cols="12">
            <b-card class="text-center">
              <h3>-- Loading.. --</h3>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col cols="12">
            <b-card class="text-center">
              <h3>-- No data loaded. Please reload the page. --</h3>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  OrderDetails, ProductDetails, DeliveryDestination, DeliveryOrigin, SmartTruck, ProofDelivery,
} from '@/components'

export default {
  name: 'OrderSummary',
  components: {
    OrderDetails,
    ProductDetails,
    DeliveryDestination,
    DeliveryOrigin,
    SmartTruck,
    ProofDelivery,
  },
  computed: {
    ...mapGetters({
      isLoading: 'orders/checkLoadingState',
      order: 'orders/orderDetails',
    }),
  },
  created() {
    this.getOrder()
  },
  methods: {
    getOrder() {
      const id = this.$route.params.orderId
      this.$store.dispatch('orders/fetchOrder', { id })
    },
  },
}
</script>
<style scoped>
</style>
